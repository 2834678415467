import React, { useEffect, useState } from 'react';

import { isNotSSR } from '@utils';

import { IPost } from '../../components/Blog/ArticleItem';
import { BlogHero } from '../../components/Blog/BlogHero';
import { BlogList } from '../../components/Blog/BlogList';
import { ScPage } from '../../components/page/styled';
import { getPosts } from '../../helpers/blog';
import { useMatchMedia } from '../../hooks/use-match-media';
import { MainLayout } from '../../layouts/main-layout';
import { AboutUsInfoSection } from '../../sections/AboutUs/AboutUsInfoSection';
import { AboutUsReadyToStartSaving } from '../../sections/AboutUs/ReadyToStartSaving';
import {SEO} from "../../components/Seo/Seo";

const BlogListPage = () => {
	const [posts, setPosts] = useState<IPost[]>([]);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const params = new URLSearchParams(isNotSSR ? window.location.search : '');
	const page = Number(params.get('page')) || 1;
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);

	useEffect(() => {
		const debounceTimeout = setTimeout(() => {
			async function fetchPosts() {
				setIsLoading(true);
				const { posts, meta } = await getPosts(searchQuery, page, isDesktop ? 12 : 6);
				setPosts(posts);
				setTotalPages(meta.pagination.total);
				setIsLoading(false);
			}

			fetchPosts();
		}, 1000);

		return () => clearTimeout(debounceTimeout);
	}, [searchQuery, page, isDesktop]);

	return (
		<MainLayout>
			<ScPage>
				<BlogHero setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
				<BlogList posts={posts} isLoading={isLoading} totalPages={totalPages} />
				<AboutUsInfoSection />
				<AboutUsReadyToStartSaving />
			</ScPage>
		</MainLayout>
	);
};

export default BlogListPage;

export const Head = () => (
	<SEO
		title={'The Extra Slice | Gogeta Blog - Employee Benefits Simplified'}
		description={'Gogeta\'s Extra Slice Blog: Discover how salary sacrifice benefits give employees' +
			' an extra slice of their salary. Read our guides and top tips to maximise savings.'}
	/>
)
