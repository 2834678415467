import React from 'react';

import { isNotSSR } from '@utils';

import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import Spinner from '../../components/spinner/index';
import { useMatchMedia } from '../../hooks/use-match-media';
import { ScContainer } from '../container/styled';

import { ArticleItem, IPost, PostType } from './ArticleItem';
import { Pagination } from './Pagination';
import { ScBlogListItems, ScBlogListWrapper } from './styled';

export const BlogList = ({
	posts,
	isLoading,
	totalPages,
}: {
	posts: IPost[];
	isLoading: boolean;
	totalPages: number;
}) => {
	const params = new URLSearchParams(isNotSSR ? window.location.search : '');
	const page = Number(params.get('page')) || 1;
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);

	return (
		<ScBlogListWrapper id="articles">
			<Top />
			<div>
				<ScContainer>
					{isLoading ? (
						<Spinner />
					) : (
						<>
							<ScBlogListItems>
								{posts?.map((post: IPost, index: number) => {
									const cycleIndex = (index + 1) % 11;
									let type = PostType.MEDIUM;
									if (cycleIndex === 1 || cycleIndex === 7) {
										type = PostType.BIG;
									} else if (
										(cycleIndex > 1 && cycleIndex <= 4) ||
										(cycleIndex > 7 && cycleIndex <= 10)
									) {
										type = PostType.SMALL;
									}

									return <ArticleItem key={post?.uuid} post={post} type={type} />;
								})}
							</ScBlogListItems>
							<div className="pagination">
								<Pagination
									totalCount={totalPages}
									pageSize={isDesktop ? 12 : 6}
									page={page}
									siblingCount={1}
								/>
							</div>
						</>
					)}
				</ScContainer>
			</div>
			<Bot />
		</ScBlogListWrapper>
	);
};
