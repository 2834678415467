import React from 'react';

import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ScContainer } from '../container/styled';

import { ScBlogHeroWrapper, ScBlogSearchWrapper } from './styled';

export const BlogHero = ({
	searchQuery,
	setSearchQuery,
}: {
	searchQuery: string;
	setSearchQuery: (value: string) => void;
}) => {
	return (
		<ScContainer>
			<ScBlogHeroWrapper>
				<h3>The Extra Slice</h3>
				<p>We blog about how the power of salary sacrifice can make payslips go further</p>
				<ScBlogSearchWrapper>
					<Search />
					<input
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						placeholder="Search by topic or keywords"
					/>
				</ScBlogSearchWrapper>
			</ScBlogHeroWrapper>
		</ScContainer>
	);
};
