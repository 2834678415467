import * as React from 'react';
import { navigate } from 'gatsby';

import { ReactComponent as Arrow } from '../../assets/icons/pagination-arrow.svg';
import { DOTS, usePagination } from '../../hooks/use-pagination';

import { PaginationWrapper } from './styled';

interface PaginationType {
	totalCount: number;
	pageSize: number;
	siblingCount?: number;
	page: number;
}

export const Pagination: React.FC<PaginationType> = (props) => {
	const { totalCount, siblingCount, page, pageSize } = props;

	const paginationRange = usePagination({
		page,
		totalCount,
		siblingCount,
		pageSize,
	});

	if (page === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		if (page !== lastPage) {
			navigate(`?page=${(page + 1).toString()}#articles`);
		}
	};

	const onPrevious = () => {
		if (page !== 1) {
			navigate(`?page=${(page - 1).toString()}#articles`);
		}
	};

	const onPageNumberClick = (pageNumber: number) => {
		navigate(`?page=${pageNumber.toString()}#articles`);
	};

	const lastPage = paginationRange[paginationRange.length - 1];
	return (
		<PaginationWrapper>
			<Arrow className={page === 1 ? 'disabled' : ''} onClick={onPrevious} />
			{paginationRange.map((pageNumber) => {
				if (pageNumber === DOTS) {
					return <li className="pagination-item dots">&#8230;</li>;
				}

				return (
					<li
						className={pageNumber === page ? 'selected' : ''}
						onClick={() => onPageNumberClick(pageNumber)}
					>
						<a>{pageNumber}</a>
					</li>
				);
			})}
			<Arrow className={page === lastPage ? 'disabled' : ''} onClick={onNext} />
		</PaginationWrapper>
	);
};
