import * as React from 'react';

import { useMatchMedia } from './use-match-media';

interface UsePaginationType {
	totalCount: number;
	pageSize: number;
	siblingCount: number;
	page: number;
}

export const DOTS = '...';

const range = (start, end) => {
	const length = end - start + 1;
	return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ totalCount, pageSize, siblingCount, page }: UsePaginationType) => {
	const isTablet = useMatchMedia(`(min-width: 768px)`);

	const paginationRange = React.useMemo(() => {
		const totalPageCount = Math.ceil(totalCount / pageSize);
		const totalPageNumbers = siblingCount + 5;

		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(page - siblingCount, 1);
		const rightSiblingIndex = Math.min(page + siblingCount, totalPageCount);

		const shouldShowLeftDots = leftSiblingIndex > (isTablet ? 3 : 2);
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - (isTablet ? 2 : 1);

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = isTablet ? 5 : 4;
			const leftRange = range(1, leftItemCount);

			return [...leftRange, DOTS, totalPageCount]; //  limit numbers before dots
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = isTablet ? 5 : 4;
			const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);

			return [firstPageIndex, DOTS, ...rightRange]; //  limit numbers after dots
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);

			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}
	}, [totalCount, pageSize, siblingCount, page]);

	return paginationRange;
};
